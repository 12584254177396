import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
} from "reactstrap";
import useSubscribeToStrategy from "../hooks/useSubscribeToStrategy";

function SubscribeToStrategy() {
  const { loading, result, responseMessage, subscribeToStrategy } =
    useSubscribeToStrategy();
  const [strategyName, setStrategyName] = useState("");
  const [apiKeyId, setApiKeyId] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    subscribeToStrategy(strategyName, apiKeyId);
  };

  return (
    <Card>
      <CardHeader>
        <h5 className="title">Subscribe to Strategy</h5>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <label>Strategy Name</label>
                <Input
                  value={strategyName}
                  onChange={(e) => setStrategyName(e.target.value)}
                  placeholder="Enter Strategy Name"
                  type="text"
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>API Key ID</label>
                <Input
                  value={apiKeyId}
                  onChange={(e) => setApiKeyId(e.target.value)}
                  placeholder="Enter API Key ID"
                  type="text"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Button type="submit">Subscribe</Button>
        </Form>
        {loading && <p>Loading...</p>}
        {result && <p>Result: {result}</p>}
        {responseMessage && <p>{responseMessage}</p>}
      </CardBody>
    </Card>
  );
}

export default SubscribeToStrategy;
