import { useState } from "react";
import axios from "axios";

function useCreateStrategy() {
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const createStrategy = async (data) => {
    if (!data) {
      console.error("Data object is missing.");
      return;
    }

    setLoading(true);

    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/create_stream`;

    const queryParams = {
      name: data.name,
      trading_type: data.tradingType,
      APY: data.apy,
      description: data.description,
      period: data.periods,
      limits: data.limits,
      price: data.price,
    };

    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
      .join("&");

    const fullUrl = `${baseUrl}?${queryString}`;

    const payload = {
      market_names:
        data.marketNames?.split(",").map((item) => item.trim()) || [],
      tags: data.tags?.split(",").map((item) => item.trim()) || [],
    };

    try {
      const response = await axios.post(fullUrl, payload, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      setResponseMessage(response.data.message);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        setResponseMessage(
          error.response.data.detail || error.response.statusText
        );
      } else if (error.request) {
        setResponseMessage("No response from the server.");
      } else {
        setResponseMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return { responseMessage, loading, createStrategy };
}

export default useCreateStrategy;
