import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import useCreateKey from "../hooks/useCreateKey";

function CustomForm() {
  const { loading, responseMessage, createKey } = useCreateKey();
  const [extUserId, setExtUserId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [marketName, setMarketName] = useState("");
  const [passphrase, setPassphrase] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await createKey(extUserId, apiKey, secretKey, marketName, passphrase);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">API Configuration</h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>External User ID</label>
                        <Input
                          value={extUserId}
                          onChange={(e) => setExtUserId(e.target.value)}
                          placeholder="Enter External User ID"
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="6">
                      <FormGroup>
                        <label>API Key</label>
                        <Input
                          value={apiKey}
                          onChange={(e) => setApiKey(e.target.value)}
                          placeholder="Enter API Key"
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Secret Key</label>
                        <Input
                          value={secretKey}
                          onChange={(e) => setSecretKey(e.target.value)}
                          placeholder="Enter Secret Key"
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Market Name</label>
                        <Input
                          value={marketName}
                          onChange={(e) => setMarketName(e.target.value)}
                          placeholder="Enter Market Name"
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Passphrase</label>
                        <Input
                          value={passphrase}
                          onChange={(e) => setPassphrase(e.target.value)}
                          placeholder="Enter Passphrase"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {loading ? <p>Loading...</p> : null}
                  {responseMessage ? <p>{responseMessage}</p> : null}
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Col>
          {/* <div>
            <GetKeys />
          </div>

          <div>
            <DeleteKey />
          </div>

          <div>
            <CreateStrategyForm />
          </div>

          <div>
            <GetStrategys />
          </div>

          <div>
            <GetStrategyData />
          </div>

          <div>
            <SubscribeToStrategy />
          </div>

          <div>
            <UnsubscribeFromStrategy />
          </div>

          <div>
            <GetSubscriptions />
          </div>

          <div>
            <GetStatsByKey />
          </div>

          <div>
            <GetUsersByStrategy />
          </div>

          <div>
            <GetOpenOrdersByKey />
          </div>

          <div>
            <GetTransactions />
          </div> */}
        </Row>
      </div>
    </>
  );
}

export default CustomForm;
