import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";
import useGetApiKeys from "../hooks/useGetKeys";
import "../assets/scss/black-dashboard-react/custom/forms/_form-page.scss";

function GetApiKeysTable() {
  const { loading, responseMessage, getApiKeys, apiKeys } = useGetApiKeys();
  const [extUserId, setExtUserId] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await getApiKeys(extUserId);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Get API Keys by User</CardTitle>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col md="8">
                    <Input
                      value={extUserId}
                      onChange={(e) => setExtUserId(e.target.value)}
                      placeholder="Enter External User ID"
                      type="text"
                      required
                    />
                  </Col>
                  <Col md="1">
                    <Button color="primary" onClick={handleSubmit}>
                      Get
                    </Button>
                  </Col>
                </Row>
                <Table className="tablesorter">
                  <thead className="text-primary">
                    <tr>
                      <th>API Key ID</th>
                      <th>Market Name</th>
                      <th>Status</th>
                      <th className="text-center">Date Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="4" className="text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : apiKeys.length > 0 ? (
                      apiKeys.map((apiData, index) => (
                        <tr key={index}>
                          <td>{apiData.api_key_id}</td>
                          <td>{apiData.market_name}</td>
                          <td>{apiData.status}</td>
                          <td className="text-center">
                            {new Date(
                              apiData.created_date * 1000
                            ).toLocaleDateString()}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          {responseMessage}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default GetApiKeysTable;
