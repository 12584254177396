import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import useDeleteKey from "../hooks/useDeleteKey";

function DeleteKey() {
  const { loading, responseMessage, deleteKey } = useDeleteKey();
  const [apiKeyId, setApiKeyId] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await deleteKey(apiKeyId);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Delete API Key</h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <label>API Key ID</label>
                        <Input
                          value={apiKeyId}
                          onChange={(e) => setApiKeyId(e.target.value)}
                          placeholder="Enter API Key ID to delete"
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {loading ? <p>Loading...</p> : null}
                  {responseMessage ? <p>{responseMessage}</p> : null}
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="danger"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Delete
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DeleteKey;
