import { useState } from "react";
import axios from "axios";

const useGetOpenOrdersByKey = () => {
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const getOpenOrdersByKey = async (
    apiKeyId,
    testnet = true,
    streamName = ""
  ) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/statistics/get_positions/?api_key_id=${apiKeyId}&testnet=${testnet}&stream_name=${streamName}`
      );

      console.log(response);
      if (response.data && Array.isArray(response.data)) {
        setPositions(response.data);
      } else if (response.data.message === "Error") {
        setErrorMessage(response.data.result);
      } else {
        setErrorMessage(response.data.error);
        setPositions([]);
      }
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    positions,
    errorMessage,
    getOpenOrdersByKey,
  };
};

export default useGetOpenOrdersByKey;
