import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import useChangeStrategyStatus from "../hooks/useChangeStrategyStatus"; // Adjust path if needed

function ChangeStrategyStatus() {
  const { loading, responseMessage, changeStrategyStatus } =
    useChangeStrategyStatus();
  const [strategyName, setStrategyName] = useState("");
  const [newStatus, setNewStatus] = useState("ENABLED");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await changeStrategyStatus(strategyName, newStatus);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Change Strategy Status</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label for="strategyName">Strategy Name</Label>
                  <Input
                    type="text"
                    name="strategyName"
                    id="strategyName"
                    placeholder="Enter Strategy Name"
                    value={strategyName}
                    onChange={(e) => setStrategyName(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="newStatus">New Status</Label>
                  <Input
                    type="select"
                    name="newStatus"
                    id="newStatus"
                    value={newStatus}
                    onChange={(e) => setNewStatus(e.target.value)}
                  >
                    <option value="ENABLED">ENABLED</option>
                    <option value="DISABLED">DISABLED</option>
                    <option value="HIDDEN">HIDDEN</option>
                  </Input>
                </FormGroup>
                <Button color="primary" onClick={handleSubmit}>
                  Change Status
                </Button>
                {loading && <p>Loading...</p>}
                {responseMessage && <p>{responseMessage}</p>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ChangeStrategyStatus;
