import { useState } from "react";
import axios from "axios";

const useDeleteKey = () => {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const deleteKey = async (apiKeyId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/delete_key`,
        {},
        {
          params: {
            api_key_id: apiKeyId,
          },
          headers: {
            accept: "application/json",
          },
        }
      );

      console.log("API Response:", response);

      if (response.data && response.data.message) {
        setResponseMessage(response.data.message);
      } else {
        setResponseMessage("Unexpected response format.");
      }
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    responseMessage,
    deleteKey,
  };
};

export default useDeleteKey;
