import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
} from "reactstrap";
import useGetTransactions from "../hooks/useGetTransactions";

function GetTransactions() {
  const { loading, transactions, errorMessage, getTransactions } =
    useGetTransactions();

  const [apiKeyId, setApiKeyId] = useState("");
  const [streamKey, setStreamKey] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    getTransactions(apiKeyId, streamKey, dateFrom, dateTo);
  };

  return (
    <Card>
      <CardHeader>
        <h5 className="title">Get Transactions</h5>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <label>API Key ID</label>
                <Input
                  value={apiKeyId}
                  onChange={(e) => setApiKeyId(e.target.value)}
                  placeholder="Enter API Key ID"
                  type="text"
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Strategy Name</label>
                <Input
                  value={streamKey}
                  onChange={(e) => setStreamKey(e.target.value)}
                  placeholder="Enter Strategy "
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Date From</label>
                <Input
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                  placeholder="YYYY-MM-DD"
                  type="date"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Date To</label>
                <Input
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                  placeholder="YYYY-MM-DD"
                  type="date"
                />
              </FormGroup>
            </Col>
          </Row>
          <Button type="submit">Fetch Transactions</Button>
        </Form>
        {loading && <p>Loading...</p>}
        {Array.isArray(transactions) && transactions.length > 0 ? (
          <ul>
            {transactions[0].result ===
            '{"error": "APIError(code=-2015): Invalid API-key, IP, or permissions for action."}' ? (
              <p>"Invalid API-key or no permission"</p>
            ) : transactions[0].result ===
              '{"error": "APIError(code=-2014): API-key format invalid."}' ? (
              <p>"API-key format invalid."</p>
            ) : (
              transactions.map((transaction, index) => (
                <li key={index}>{JSON.stringify(transaction)}</li>
              ))
            )}
            {console.log(transactions)}
          </ul>
        ) : null}
        {errorMessage && <p>{errorMessage}</p>}
      </CardBody>
    </Card>
  );
}

export default GetTransactions;
