import { useState } from "react";
import axios from "axios";

function useGetTransactions() {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const getTransactions = async (apiKeyId, streamKey, dateFrom, dateTo) => {
    const formattedDateFrom = `${dateFrom}T00:00:00.000Z`;
    const formattedDateTo = `${dateTo}T23:59:59.999Z`;

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/statistics/get_transactions_by_key/?api_key_id=${apiKeyId}&stream_name=${streamKey}&date_from=${formattedDateFrom}&date_to=${formattedDateTo}`
      );
      console.log(response.data);
      setTransactions(response.data.data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.detail?.map((e) => e.msg).join(", ") ||
          "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    transactions,
    errorMessage,
    getTransactions,
  };
}

export default useGetTransactions;
