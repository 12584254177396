import { useState } from "react";
import axios from "axios";

const useGetStrategyData = () => {
  const [loading, setLoading] = useState(false);
  const [strategyData, setStrategyData] = useState({});
  const [responseMessage, setResponseMessage] = useState("");

  const getStrategyData = async (strategyName) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get_stream_data/?stream_name=${strategyName}`,
        {},
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (
        response.data &&
        response.data.result === "Success" &&
        response.data.data
      ) {
        setStrategyData(response.data.data);
      } else if (response.data && response.data.message) {
        setResponseMessage(`Error: ${response.data.message}`);
      } else {
        setResponseMessage("Unexpected response format");
      }
    } catch (error) {
      setResponseMessage(
        `Error: ${error.message}. Server response: ${
          error.response?.data?.message || ""
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    strategyData,
    responseMessage,
    getStrategyData,
  };
};

export default useGetStrategyData;
