import { useState } from "react";
import axios from "axios";

const useGetUserSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");

  const getUserSubscriptions = async (extUserId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get_streams_by_user/?ext_user_id=${extUserId}`
      );
      console.log(response);

      if (response.data.result === "Success") {
        setSubscriptions(response.data.data);
        setResponseMessage(response.data.message);
      } else {
        setResponseMessage(response.data.error);
        setResponseMessage(response.data.message);
      }
    } catch (error) {
      setResponseMessage("Failed to fetch data");
    }
    setLoading(false);
  };

  return {
    loading,
    subscriptions,
    responseMessage,
    getUserSubscriptions,
  };
};

export default useGetUserSubscriptions;
