import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";
import useGetUsersByStrategy from "../hooks/useGetUsersByStrategy";

function GetUsersByStrategy() {
  const { loading, responseMessage, getUsersByStrategy, apiKeysList } =
    useGetUsersByStrategy();

  const [strategyName, setStrategyName] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await getUsersByStrategy(strategyName);
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">List Users By Strategy</CardTitle>
            </CardHeader>
            <CardBody>
              <Row form>
                <Col md="8">
                  <Input
                    value={strategyName}
                    onChange={(e) => setStrategyName(e.target.value)}
                    placeholder="Enter Strategy Name"
                    type="text"
                    required
                  />
                </Col>
                <Col md="1">
                  <Button color="primary" onClick={handleSubmit}>
                    Get
                  </Button>
                </Col>
              </Row>

              <Table className="tablesorter">
                <thead className="text-primary">
                  <tr>
                    <th>Amount Active</th>
                    <th>Amount All</th>
                    <th>PnL</th>
                    <th>Volume Active</th>
                    <th>Volume All</th>
                    <th>Winrate</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : apiKeysList ? (
                    <tr>
                      <td>{apiKeysList.amount}</td>
                      <td>{apiKeysList.amount_all}</td>
                      <td>{apiKeysList.pnl}</td>
                      <td>{apiKeysList.volume}</td>
                      <td>{apiKeysList.volume_all}</td>
                      <td>{apiKeysList.winrate}%</td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        {responseMessage}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default GetUsersByStrategy;
