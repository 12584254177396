import React from "react";

// Importing your components
import CreateKey from "./CreateKey";
import CreateStrategy from "./CreateStrategy";
import DeleteKey from "./DeleteKey";
import GetKeys from "./GetKeys";
import GetStrategys from "./GetStrategys";
import GetOpenOrdersByKey from "./GetOpenOrdersByKey";
import GetStatsByKey from "./GetStatsByKey";
import GetStrategyData from "./GetStrategyData";
import GetTransactions from "./GetTransactions";
import GetUsersByStrategy from "./GetUsersByStrategy";
import GetSubscriptions from "./GetSubscriptions";
import SubscribeToStrategy from "./SubscribeToStrategy";
import UnsubscribeFromStrategy from "./UnsubscribeFromStrategy";
import "../assets/scss/black-dashboard-react/custom/forms/_form-page.scss";
import ChangeStrategyStatus from "./ChangeStrategyStatus";

const FormsPage = () => {
  const sections = [
    { name: "Create Key", anchor: "create-key", component: <CreateKey /> },
    {
      name: "Create Strategy",
      anchor: "create-strategy",
      component: <CreateStrategy />,
    },
    { name: "Delete Key", anchor: "delete-key", component: <DeleteKey /> },
    { name: "Get Keys", anchor: "get-keys", component: <GetKeys /> },
    {
      name: "Get List Of Strategy",
      anchor: "get-list-of-strategy",
      component: <GetStrategys />,
    },
    {
      name: "Get Open Orders By Key",
      anchor: "get-open-orders-by-key",
      component: <GetOpenOrdersByKey />,
    },
    {
      name: "Get Stats By Key",
      anchor: "get-stats-by-key",
      component: <GetStatsByKey />,
    },
    {
      name: "Get Strategy Data",
      anchor: "get-strategy-data",
      component: <GetStrategyData />,
    },
    {
      name: "Get Transactions",
      anchor: "get-transactions",
      component: <GetTransactions />,
    },
    {
      name: "Get Users By Strategy",
      anchor: "get-users-by-strategy",
      component: <GetUsersByStrategy />,
    },
    {
      name: "Get User Subscriptions",
      anchor: "get-user-subscriptions",
      component: <GetSubscriptions />,
    },
    {
      name: "Subscribe To Strategy",
      anchor: "subscribe-to-strategy",
      component: <SubscribeToStrategy />,
    },
    {
      name: "Unsubscribe From Strategy",
      anchor: "unsubscribe-from-strategy",
      component: <UnsubscribeFromStrategy />,
    },
    {
      name: "Change Strategy Status",
      anchor: "change-strategy-status",
      component: <ChangeStrategyStatus />,
    },
  ];
  return (
    <>
      <div className="form-page-container">
        {sections.map((section, key) => (
          <div id={section.anchor} key={key}>
            <h2>{section.name}</h2>
            {section.component}
          </div>
        ))}
      </div>
    </>
  );
};

export default FormsPage;
