import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";

import useGetUserSubscriptions from "../hooks/useGetUserSubscriptions";

function GetSubscriptions() {
  const { loading, responseMessage, getUserSubscriptions, subscriptions } =
    useGetUserSubscriptions();
  const [extUserId, setExtUserId] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await getUserSubscriptions(extUserId);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">List User Subscriptions</CardTitle>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col md="8">
                    <Input
                      value={extUserId}
                      onChange={(e) => setExtUserId(e.target.value)}
                      placeholder="Enter External User ID"
                      type="text"
                      required
                    />
                  </Col>
                  <Col md="1">
                    <Button color="primary" onClick={handleSubmit}>
                      Get
                    </Button>
                  </Col>
                </Row>
                <Table className="tablesorter">
                  <thead className="text-primary">
                    <tr>
                      <th>Stream Name</th>
                      <th>API Key ID</th>
                      <th>Date Subscribed</th>
                      <th>Status</th>
                      <th>Date Unsubscribed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : subscriptions.length > 0 ? (
                      subscriptions.map((sub, index) => (
                        <tr key={index}>
                          <td>{sub.stream_name}</td>
                          <td>{sub.api_key_id}</td>
                          <td>
                            {new Date(
                              sub.subscribe_date * 1000
                            ).toLocaleDateString()}
                          </td>
                          <td>{sub.status}</td>
                          <td>
                            {sub.unsubscribe_date > 0
                              ? new Date(
                                  sub.unsubscribe_date * 1000
                                ).toLocaleDateString()
                              : "N/A"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          {responseMessage === "List of streams."
                            ? "No Subscriptions found"
                            : responseMessage}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default GetSubscriptions;
