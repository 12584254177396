import { useState } from "react";
import axios from "axios";

const useChangeStrategyStatus = () => {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const changeStrategyStatus = async (strategyName, newStatus) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/change_stream_status`,
        {},
        {
          params: {
            stream_name: strategyName,
            new_status: newStatus,
          },
          headers: {
            accept: "application/json",
          },
        }
      );

      if (response.data && response.data.message) {
        setResponseMessage(response.data.message);
      } else {
        setResponseMessage("Successfully changed the strategy status.");
      }
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    responseMessage,
    changeStrategyStatus,
  };
};

export default useChangeStrategyStatus;
