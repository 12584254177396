import { useState } from "react";
import axios from "axios";

const useGetListOfStrategy = () => {
  const [loading, setLoading] = useState(false);
  const [strategys, setStrategys] = useState([]);
  const [error, setError] = useState(null);

  const getListOfStrategys = async (status) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get_list_of_streams/?status=${status}`
      );

      console.log("API Response:", response.data.data);

      if (response.data && response.data.data) {
        setStrategys(response.data.data);
      } else {
        setError("Unexpected response format");
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    strategys,
    error,
    getListOfStrategys, // <- Rename here
  };
};

export default useGetListOfStrategy;
