import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import useGetListOfStrategy from "../hooks/useGetListOfStrategy";

function GetStrategys() {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { loading, strategys, error, getListOfStrategys } =
    useGetListOfStrategy();

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus(status);
  };

  return (
    <Col md="8">
      <Card>
        <CardHeader>
          <h5 className="title">Get Strategys</h5>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label>Status</label>
                  <Input
                    type="select"
                    value={selectedStatus || ""}
                    onChange={handleStatusChange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value="ENABLED">ENABLED</option>
                    <option value="DISABLED">DISABLED</option>
                    <option value="HIDDEN">HIDDEN</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {console.log(strategys)}
            <ul>
              {strategys.map((strategy) => (
                <li key={strategy.name}>{strategy.name}</li>
              ))}
            </ul>
          </Form>
        </CardBody>
        <CardFooter>
          <Button
            className="btn-fill"
            color="primary"
            onClick={() => {
              if (selectedStatus) getListOfStrategys(selectedStatus);
            }}
          >
            Fetch Strategys
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
}

export default GetStrategys;
