import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
} from "reactstrap";
import useOpenOrdersByKey from "../hooks/useGetOpenOrdersByKey";

function OpenOrdersByKey() {
  const { loading, positions, errorMessage, getOpenOrdersByKey } =
    useOpenOrdersByKey();
  const [apiKeyId, setApiKeyId] = useState("");
  const [streamName, setStreamName] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    getOpenOrdersByKey(apiKeyId, true, streamName);
  };

  return (
    <Card>
      <CardHeader>
        <h5 className="title">Get Open Orders by Key</h5>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <label>API Key ID</label>
                <Input
                  value={apiKeyId}
                  onChange={(e) => setApiKeyId(e.target.value)}
                  placeholder="Enter API Key ID"
                  type="text"
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Strategy Name</label>
                <Input
                  value={streamName}
                  onChange={(e) => setStreamName(e.target.value)}
                  placeholder="Enter Strategy Name"
                  type="text"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Button type="submit">Fetch Open Orders</Button>
        </Form>
        {loading && <p>Loading...</p>}
        <ul>
          {Array.isArray(positions) &&
            positions.map((position, index) => (
              <li key={index}>{JSON.stringify(position)}</li>
            ))}
        </ul>
        {errorMessage ===
        "{'error': 'APIError(code=-2015): Invalid API-key, IP, or permissions for action.'}" ? (
          <p>"Invalid API-key or no permission"</p>
        ) : errorMessage ===
          "{'error': 'APIError(code=-2014): API-key format invalid.'}" ? (
          <p>"API-key format invalid."</p>
        ) : (
          <p>{errorMessage}</p>
        )}
      </CardBody>
    </Card>
  );
}

export default OpenOrdersByKey;
