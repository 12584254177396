import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
} from "reactstrap";
import useGetStrategyData from "../hooks/useGetStrategyData";

function GetStrategyData() {
  const { loading, strategyData, responseMessage, getStrategyData } =
    useGetStrategyData();
  const [strategyName, setStrategyName] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    getStrategyData(strategyName);
  };

  return (
    <Card>
      <CardHeader>
        <h5 className="title">Get Strategy Data</h5>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <FormGroup>
                <label>Strategy Name</label>
                <Input
                  value={strategyName}
                  onChange={(e) => setStrategyName(e.target.value)}
                  placeholder="Enter Strategy Name"
                  type="text"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Button type="submit">Fetch Data</Button>
        </Form>
        {loading && <p>Loading...</p>}
        {responseMessage && <p>{responseMessage}</p>}
        {strategyData.name && (
          <div>
            <p>
              <strong>Name:</strong> {strategyData.name}
            </p>
            <p>
              <strong>Strategy markets:</strong> {strategyData.market_names}
            </p>
            <p>
              <strong>Trading type :</strong> {strategyData.trading_type}
            </p>
            <p>
              <strong>Description:</strong> {strategyData.description}
            </p>
            <p>
              <strong>Periods:</strong> {strategyData.period}
            </p>
            <p>
              <strong>Limits:</strong> {strategyData.limits}
            </p>
            <p>
              <strong>Price:</strong> {strategyData.price}
            </p>
            <p>
              <strong>Tags:</strong> {strategyData.tags}
            </p>
            <p>
              <strong>Status:</strong> {strategyData.status}
            </p>
            <p>
              <strong>Trading_type:</strong> {strategyData.trading_type}
            </p>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default GetStrategyData;
