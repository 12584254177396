import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import useCreateStrategy from "../hooks/useCreateStrategy";

function CreateStrategy() {
  const [name, setName] = useState("");
  const [tradingType, setTradingType] = useState("SPOT");
  const [apy, setApy] = useState("");
  const [description, setDescription] = useState("");
  const [periods, setPeriods] = useState("");
  const [limits, setLimits] = useState("");
  const [price, setPrice] = useState("");
  const [marketNames, setMarketNames] = useState("");
  const [tags, setTags] = useState("");

  const { responseMessage, createStrategy } = useCreateStrategy();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handleSubmit called");
    createStrategy({
      name,
      tradingType,
      apy,
      description,
      periods,
      limits,
      price,
      marketNames,
      tags,
    });
    console.log("createStrategy called");
  };
  return (
    <div className="content">
      <Row>
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Create Strategy</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Name:</Label>
                      <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Trading Type:</Label>
                      <Input
                        type="select"
                        value={tradingType}
                        onChange={(e) => setTradingType(e.target.value)}
                      >
                        <option value="SPOT">SPOT</option>
                        <option value="FUTURES">FUTURES</option>
                        <option value="ALL">ALL</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>APY:</Label>
                      <Input
                        value={apy}
                        onChange={(e) => setApy(e.target.value)}
                        placeholder="APY"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Description:</Label>
                      <Input
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Periods:</Label>
                      <Input
                        value={periods}
                        onChange={(e) => setPeriods(e.target.value)}
                        placeholder="Periods"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Limits:</Label>
                      <Input
                        value={limits}
                        onChange={(e) => setLimits(e.target.value)}
                        placeholder="Limits"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Price:</Label>
                      <Input
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="Price"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Market Names (comma separated):</Label>
                      <Input
                        value={marketNames}
                        onChange={(e) => setMarketNames(e.target.value)}
                        placeholder="binance, kraken"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Tags (comma separated):</Label>
                      <Input
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                        placeholder="Tag1, Tag2"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <CardFooter>
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </CardFooter>
              </Form>
            </CardBody>
          </Card>
          <div>
            {Array.isArray(responseMessage) ? (
              <ul>
                {responseMessage.map((error, index) => (
                  <li key={index}>{error.msg}</li>
                ))}
              </ul>
            ) : (
              JSON.stringify(responseMessage)
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CreateStrategy;
