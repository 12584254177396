import { useState } from "react";
import axios from "axios";

const useCreateKey = () => {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const createKey = async (
    extUserId,
    apiKey,
    secretKey,
    marketName,
    passphrase,
    testnet = true
  ) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/create_key`,
        {},
        {
          params: {
            ext_user_id: extUserId,
            api_key: apiKey,
            secret_key: secretKey,
            market_name: marketName,
            passphrase: passphrase,
            testnet: testnet,
            skip_api_key_check: false,
          },
          headers: {
            accept: "application/json",
          },
        }
      );

      console.log("API Response:", response);

      if (
        response.data &&
        response.data.data &&
        response.data.data.api_key_id
      ) {
        setResponseMessage(`Success: ${response.data.data.api_key_id}`);
      } else if (response.data && response.data.message) {
        setResponseMessage(`Error: ${response.data.message}`);
      } else {
        setResponseMessage("Unexpected response format");
      }
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    responseMessage,
    createKey,
  };
};

export default useCreateKey;
