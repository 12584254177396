import { useState } from "react";
import axios from "axios";

const useGetUsersByStrategy = () => {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  // const [statistics, setStatistics] = useState({});
  const [apiKeysList, setApiKeysList] = useState([]);

  const getUsersByStrategy = async (strategyName) => {
    setLoading(true);
    try {
      const encodedStrategyName = encodeURIComponent(strategyName);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/statistics/get_users_by_stream/?stream_name=${encodedStrategyName}`
      );

      console.log(response.data);
      if (response.data.result === "Error") {
        setResponseMessage(response.data.message);
      } else {
        setApiKeysList(response.data.data); // assuming the data is directly the list
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setResponseMessage(error.response.data.detail);
      } else {
        setResponseMessage("Failed to fetch data");
      }
    }
    setLoading(false);
  };

  return { loading, responseMessage, apiKeysList, getUsersByStrategy };
};

export default useGetUsersByStrategy;
