import { useState } from "react";
import axios from "axios";

const useGetStatsByKey = () => {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");

  const getStatsByKey = async (apiKeyId, dateFrom, dateTo) => {
    setLoading(true);
    try {
      const formattedDateFrom = `${dateFrom}T00:00:00`;
      const formattedDateTo = `${dateTo}T00:00:00`;

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/statistics/get_stats_by_key/?api_key_id=${apiKeyId}&date_from=${formattedDateFrom}&date_to=${formattedDateTo}`
      );

      if (response.data.result === "Error") {
        setResponseMessage(response.data.message);
      } else if (response.data.result) {
        setStatistics(response.data.data);
      }
    } catch (error) {
      setResponseMessage("Failed to fetch data");
    }
    setLoading(false);
  };

  return {
    loading,
    statistics,
    responseMessage,
    getStatsByKey,
  };
};

export default useGetStatsByKey;
