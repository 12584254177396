import React, { useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
} from "reactstrap";

import useGetStatsByKey from "../hooks/useGetStatsByKey";

function GetStatsByKey() {
  const { loading, responseMessage, getStatsByKey, statistics } =
    useGetStatsByKey();
  const [apiKeyId, setApiKeyId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await getStatsByKey(apiKeyId, dateFrom, dateTo);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">API Key Statistics</CardTitle>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col md="3">
                    <FormGroup>
                      <Input
                        value={apiKeyId}
                        onChange={(e) => setApiKeyId(e.target.value)}
                        placeholder="API Key ID"
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Input
                        value={dateFrom}
                        onChange={(e) => setDateFrom(e.target.value)}
                        placeholder="Date From"
                        type="date"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Input
                        value={dateTo}
                        onChange={(e) => setDateTo(e.target.value)}
                        placeholder="Date To"
                        type="date"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <Button color="primary" onClick={handleSubmit}>
                      Get Stats
                    </Button>
                  </Col>
                </Row>
                <Table className="tablesorter">
                  <thead className="text-primary">
                    <tr>
                      <th>Volume</th>
                      <th>Stream Name</th>
                      <th>Amount of Trades</th>
                      <th>PNL</th>
                      <th>Win Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : statistics.length > 0 ? (
                      statistics.map((stat, index) => (
                        <tr key={index}>
                          <td>{stat.volume}</td>
                          <td>{stat.stream_name}</td>
                          <td>{stat.amount_trades}</td>
                          <td>{stat.pnl}</td>
                          <td>{stat.winrate}%</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          {responseMessage}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default GetStatsByKey;
