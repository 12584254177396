import { useState } from "react";
import axios from "axios";

const useGetApiKeys = () => {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [apiKeys, setApiKeys] = useState([]);

  const getApiKeys = async (extUserId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/get_api_keys_by_user/?ext_user_id=${extUserId}`
      );

      console.log("API Response:", response);

      if (response.data && Array.isArray(response.data.data)) {
        setApiKeys(response.data.data);
        setResponseMessage("Successfully fetched API keys.");
      } else {
        setResponseMessage("Unexpected response format.");
      }
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    apiKeys,
    responseMessage,
    getApiKeys,
  };
};

export default useGetApiKeys;
