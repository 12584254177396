import { useState } from "react";
import axios from "axios";

const useUnsubscribeFromStrategy = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const unsubscribeFromStrategy = async (strategyName, apiKeyId) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/unsubscribe_from_stream/?stream_name=${strategyName}&api_key_id=${apiKeyId}`
      );

      if (response.data && response.data.result === "Success") {
        setResult(response.data.result);
        setResponseMessage(response.data.message);
      } else if (response.data && response.data.message) {
        setResponseMessage(`Error: ${response.data.message}`);
      } else {
        setResponseMessage("Unexpected response format");
      }
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    result,
    responseMessage,
    unsubscribeFromStrategy,
  };
};

export default useUnsubscribeFromStrategy;
